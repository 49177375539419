<template>
    <div class="convertiser-auth">
        <b-row v-if="tokens.length > 0">
            <b-col md="8">
                <wit-select
                    class="text-nowrap"
                    v-model="token"
                    placeholder="Select Account"
                    :options="tokens"
                    id="token-multiselect"
                    @input="emitInput"
                    :disabled="disabled"
                    label="owner"
                    :filterBy="filterBy"
                    preselect-first
                >
                    <div slot="option" slot-scope="option">
                        <span>{{ `${option.owner.name}` }}</span>
                    </div>
                    <div slot="selected-option" slot-scope="option">
                        <span>{{ `${option.owner.name}` }}</span>
                    </div>
                </wit-select>
                <Feedback
                    :state="validateRef('token')"
                    invalid="This field is required"
                    valid="Token is valid"
                ></Feedback>
            </b-col>
            <b-col md="8">
                <span class="convertiser-reauthorize convertiser-auth-note">
                    or
                    <b-btn variant="primary" size="sm" :disabled="disabled" @click="showModal = true">click here</b-btn
                    >to use a different Account
                </span>
            </b-col>
        </b-row>
        <b-row v-if="tokens.length === 0">
            <b-col md="8">
                <span class="convertiser-auth-note">
                    <b-btn variant="primary" size="sm" :disabled="disabled" @click="showModal = true"
                        >Click here to authorize</b-btn
                    >
                    <br />You do not seem to have given us access to Convertiser APIs. You have to authorize first to
                    select an authorized account.
                </span>
            </b-col>
        </b-row>

        <WitModal
            v-model="showModal"
            title="Configure the access"
            variant="primary"
            disableDBlock
            size="lg"
            customClass="step-modal"
        >
            <wit-tabbed-form
                :formId="formId"
                :steps="steps"
                no-header
                ref="tabbedForm"
                @input="onFormInput"
                @finish="authorize"
            >
                <template v-slot:step-1-feedback>
                    <b-row>
                        <b-col>
                            <Feedback
                                v-if="tokenError"
                                :state="tokenError.state"
                                :invalid="tokenError.message"
                            ></Feedback>
                        </b-col>
                    </b-row>
                </template>
            </wit-tabbed-form>
        </WitModal>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback.vue'

import {formMixin} from '@/mixins/formMixin'

export default {
    components: {
        Feedback,
        Password: () => import('@/components/Inputs/Password.vue'),
        WitModal: () => import('@/components/Modals/WitModal.vue'),
        WitTabbedForm: () => import('@/components/WitTabbedForm.vue'),
    },
    props: {
        value: {
            type: String | null,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        projectId: {
            type: String | null,
        },
    },
    mixins: [formMixin, validationMixin],
    validations: {
        token: {required},
        convertiserForm: {
            token: {
                required,
            },
        },
    },
    watch: {
        value() {
            this.token = this.tokens.find(el => el.id === this.value)
        },
        async projectId() {
            await this.$store.dispatch('token/fetchTokens', this.projectId)
            this.token = this.tokens.find(el => el.id === this.value)
        },
    },
    async created() {
        await this.$store.dispatch('token/fetchTokens', this.projectId)
        this.token = this.tokens.find(el => el.id === this.value)
    },
    data() {
        return {
            token: null,
            showModal: false,
            cannotContinue: true,
            formId: 'convertiser-token-modal',
            convertiserForm: {
                token: '',
            },
            tokenError: {
                state: null,
                message: null,
            },
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            tokens: 'token/convertiserTokens',
        }),
        steps() {
            return [
                {
                    name: 'Authorization',
                    invalid: this.$v.convertiserForm.$invalid,
                    inputs: [
                        {
                            name: 'token',
                            value: this.$v.convertiserForm.token.$model,
                            model: this.$v.convertiserForm.token,
                            type: 'password',
                            inputLabel: 'Token',
                            placeholder: 'Enter your token',
                            invalid: 'This field is required',
                            valid: 'Token is valid',
                        },
                    ],
                },
            ]
        },
    },
    methods: {
        emitInput() {
            if (this.token) {
                this.$emit('input', this.token.id)
            } else {
                this.$emit('input', null)
            }
        },

        authorize(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.convertiserForm.$invalid) {
                this.tokenError = {
                    state: null,
                    message: null,
                }

                const data = {
                    ...this.convertiserForm,
                }

                this.$store.commit('loading/PROCESSING', `Authorizing...`)
                this.$store
                    .dispatch('convertiser/upsertToken', {data})
                    .then(async response => {
                        await this.$store.dispatch('token/fetchTokens', this.projectId)
                        this.showModal = false
                        this.$store.commit('loading/PROCESSED')
                    })
                    .catch(exception => {
                        this.tokenError = {
                            state: false,
                            message: exception.response.data.data,
                        }
                        this.$store.commit('loading/PROCESSED')
                        this.$errorHandler.report(exception, 'Could not upsert Convertiser token')
                    })
            }
        },
        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
        filterBy(option, label, search) {
            return `${option.owner.name}`.toLowerCase().indexOf(search.toLowerCase()) > -1
        },
    },
}
</script>

<style lang="scss">
.convertiser-reauthorize {
    opacity: 0.8;
}
.convertiser-auth {
    .convertiser-auth-note {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.7rem;
        img {
            width: 150px;
        }

        img.pointer-disabled {
            filter: grayscale(70%);
        }
    }
}
</style>
